<!--
 * @Description: 头部通用组件
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-30 16:53:59
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-27 16:28:24
-->
<template>
  <div class="header">
    <div class="left">
      <div class="left-logo" @click="handlePersonalClick" v-if="isShowLogo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="left-personal" @click="handlePersonalClick" v-if="isShowPersonal">个人中心</div>
    </div>
    <div class="center">
      <div class="center-function" v-if="isShowFunction">
        <div @click="handlePreviewClick"><span class="iconfont">&#xe852;</span>预览</div>
        <div @click="handleSaveClick"><span class="iconfont">&#xe63b;</span>保存</div>
        <div @click="handlePublicClick"><span class="iconfont">&#xe626;</span>发布</div>
      </div>
      <div class="center-tab" v-if="isShowTab">
        <div :class="{'active': currentTab === 1}" @click="handleEditQuestionClick">编辑问卷</div>
        <div :class="{'active': currentTab === 2}" @click="handleDispatchQuestionClick">派发问卷</div>
        <div :class="{'active': currentTab === 3}" @click="handleRecycleClick">回收情况</div>
        <div :class="{'active': currentTab === 4}" @click="handleAnalysisClick">结果分析</div>
      </div>
    </div>
    <div class="right">
      <div class="right-personal" v-if="isShowHelp">
        <div class="right-personal-helps">帮助</div>
        <div class="right-personal-username">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              登录成功
            </a>
            <template #overlay>
              <a-menu @click="handleLoginOutClick">
                <a-menu-item>登出</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="right-function" v-if="isShowExport">
        <div class="right-function-test">
          测试作答
          <a-switch 
            size="small" 
            v-model:checked="testAnswerChecked"
            @change="handleTestChange"
          />
        </div>
        <div class="right-personal-export" @click="handleExport">
          <span class="iconfont">&#xe70f;</span>
          导出问卷
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Keycloak from "keycloak-js";
import { message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, watchEffect } from 'vue'
import { exportQuestionnaire } from '@/api/questionnaire'
import { saveQuestionnaire, saveEditQuestionnaire, releaseQuestionnaire } from '@/api/questionnaire'
import globalQuestionnaire from '@/class/useQuestionnaire'
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'

/**
 * 问卷详情头部点击事件
 */
const questionnaireDetailEffect = (emit) => {
  const route = useRoute()
  // 初始化是根据route.name，来决定进入哪个tab
  const tabRouteNameArr = ['null', 'EditQuestion', 'DispatchQuestion', 'RecycleBin', 'Analysis']
  const currentTab = ref()
  watchEffect(() => {
    currentTab.value = tabRouteNameArr.indexOf(route.name)
  })
  const handleEditQuestionClick = () => { 
    currentTab.value = 1; 
    emit('edit') 
  }
  const handleDispatchQuestionClick = () => { 
    currentTab.value = 2
    emit('dispatch') 
  }
  const handleRecycleClick = () => { 
    currentTab.value = 3
    emit('recycle') 
  }
  const handleAnalysisClick = () => { 
    currentTab.value = 4
    emit('analysis') 
  }

  return {
    currentTab,
    handleEditQuestionClick,
    handleDispatchQuestionClick,
    handleRecycleClick,
    handleAnalysisClick,
  }
}

/**
 * 问卷编辑头部点击事件
 */
const questionnaireEditEffect = (props, emit) => {
  const route = useRoute()
  const router = useRouter()
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const { filterQuestionaireEffect } = useCommonQuestionnaireEffect()
  // 自动补全默认问卷标题和章节名称
  const autoCompletionText = (questionnaire) => {
    if (!questionnaire.title) {
      questionnaire.title = '问卷标题'
    }
    Object.values(questionnaire.partMap).forEach((partData, index) => {
      if (!partData.title) {
        partData.title = '章节标题'
      }
    })
    questionnaire.questions
      .filter(question => question.questionType === 'PART')
      .forEach(question => !question.title ? (question.title = '章节标题') : null)
  }
  // 个人主页
  const handlePersonalClick = () => {
    if (props.isForbidLogoClick) return
    router.push('/personal')
  }
  // 保存
  const handleSaveClick =  () => { emit('save') }
  // 预览
  const handlePreviewClick = async () => {
    autoCompletionText(questionnaire)
    const newQuestionnaire = filterQuestionaireEffect(questionnaire)
    let id = ''
    let res = null
    if (route.params.id == 'new') {
      res = await saveQuestionnaire(newQuestionnaire)
      if (res.data?.code === '00000') {
        id = res.data.data
        router.push({
          name: 'EditQuestion',
          params: {
            id
          }
        })
      }
    } else {
      id = route.params.id
      delete newQuestionnaire.questionnaireProtoId
      res = await saveEditQuestionnaire(newQuestionnaire, id)
    }
    if (res.data?.code != '00000') {
      message.error('预览失败')
      return
    }
    const obj = router.resolve({
      path: `/preview/${id}`,
    });
    window.open(obj.href, "_blank");
  }
  // 发布
  const handlePublicClick = async () => {
    autoCompletionText(questionnaire)
    const newQuestionnaire = filterQuestionaireEffect(questionnaire)
    let id = ''
    let res = null
    // 先保存再发布
    if (route.params.id == 'new') {
      res = await saveQuestionnaire(newQuestionnaire)
      if (res.data?.code === '00000') {
        id = res.data.data
      }
    } else {
      id = route.params.id
      delete newQuestionnaire.questionnaireProtoId
      res = await saveEditQuestionnaire(newQuestionnaire, id)
    }
    if (res.data?.code != '00000') {
      message.error('发布失败')
      return
    }
    let resTwo = await releaseQuestionnaire(id)
    if (resTwo.data?.code === '00000') {
      message.success(`发布成功`)
      router.push(`/detail/${id}/dispatchQuestion`)
    } else {
      message.error(`发布失败，失败原因：${res.msg}`)
    }
  }
  return {
    handlePersonalClick,
    handleSaveClick,
    handlePublicClick,
    handlePreviewClick
  }
}

/**
 * 预览页头部控制事件
 */
const previewControlEffect = (emit) => {
  const route = useRoute()
  const testAnswerChecked = ref(false)
  // 测试作答switch切换
  const handleTestChange = (checked) => {
    emit('testAnswerChange', checked)
  }
  // 导出问卷
  const handleExport = () => {
    let url = exportQuestionnaire()
    // console.log("从api层获得的url", url);
    let params = {
      questionnaireProtoId: route.params.id
    }
    let pairs = [];
    for (let name in params) {
      let value = params[name].toString();
      // encodeURIComponent()对URL中的键值对进行编码
      name = encodeURIComponent(name);
      value = encodeURIComponent(value);
      // 将转换好的键值对,存入容器中
      pairs.push(name + "=" + value);
    }
    const query = pairs.join("&"); // 将键值对之间用'&'拼接后返回
    url = url + "?" + query;
    window.open(url);
  }
  return {
    testAnswerChecked,
    handleTestChange,
    handleExport
  }
}

/**
 * 个人信息栏点击事件
 */
const personalEffect = (emit) => {
  // 登出
  const handleLoginOutClick = () => {
    const keycloakOptions = {
      realm:'business-account',
      url: process.env.VUE_APP_BASE_LOGIN_URL,
      clientId:'survey-web',
    }

    const keycloak = Keycloak(keycloakOptions);
    keycloak.init({
      'flow': 'implicit',
      "enable-cors": true,
    })
    const redirectURL =  `${process.env.VUE_APP_BASE_URL}/sso/login?return-url=${encodeURIComponent(window.location.href)}`;

    console.log(keycloak)
    console.log('request logout')
    keycloak.logout({
      'redirectUri': redirectURL
    })
  }
  return {
    handleLoginOutClick
  }
}
export default({
  name: 'Header',
  props: [
    'isShowLogo',
    'isForbidLogoClick', // 是否禁止logo区域点击事件
    'isShowPersonal', 
    'isShowTab', 
    'isShowFunction', 
    'isShowExport', 
    'isShowHelp'
  ],
  emits: [
    'edit',
    'dispatch',
    'recycle',
    'analysis',
    'save',
    'preview',
    'handlePublic',
    'testAnswerChange',
  ],
  setup(props, { emit }) {
    const loginPageUrl = process.env.VUE_APP_BASE_LOGIN_URL
    const { handlePersonalClick, handleSaveClick, handlePublicClick, handlePreviewClick } = questionnaireEditEffect(props, emit)
    const { currentTab, handleEditQuestionClick, handleDispatchQuestionClick, 
      handleRecycleClick, handleAnalysisClick } = questionnaireDetailEffect(emit)
    const { testAnswerChecked, handleTestChange, handleExport } = previewControlEffect(emit)
    const { handleLoginOutClick } = personalEffect(emit)
    
    return {
      currentTab,
      loginPageUrl,
      handlePersonalClick,
      handleSaveClick,
      handlePublicClick,
      handlePreviewClick,
      handleEditQuestionClick,
      handleDispatchQuestionClick,
      handleRecycleClick,
      handleAnalysisClick,
      handleExport,
      testAnswerChecked,
      handleTestChange,
      handleLoginOutClick,
    }
  }
})
</script>

<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  display: flex;
  text-align: center;
  line-height: 56px;
  background-color: #fff;
  border-bottom: 1px solid @backgroundColor;
  z-index: 99;
  font-size: 14px;
  .left {
    flex: 0 1 260px;
    display: flex;
    align-items: center;
    div {
      cursor:pointer;
      // &:hover {
      //   background-color: @hoverBackground;
      // }
    }
    .left-logo {
      height: 80%;
      width: 240px;
      border-right: 3px solid #eff2f5;
      img {
        height: 100%;
      }
    }
    .left-personal {
      width: 100px;
      border-right: 3px solid #eff2f5;
    }
  }
  .center {
    flex: 1 1 auto;
    .center-tab {
      display: flex;
      justify-content: space-between;
      width: 480px;
      margin: 0 auto;
      div {
        flex: 0 1 96px;
        .pointer()
      }
    }
    .center-function {
      display: flex;
      justify-content: space-between;
      width: 320px;
      margin: 0 auto;
      font-size: 16px;
      div {
        flex: 0 1 96px;
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      }
      .iconfont {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .active {
        color: #f59f34;
        border-bottom: 3px solid #f59a23;
    }
  }
  .right {
    flex: 0 1 260px;
    .right-personal {
      display: flex;
      div:hover {
        background-color: @hoverBackground;
      }
      .right-personal-helps {
        flex: 0 1 120px;
        cursor: pointer;
      }
      .right-personal-username {
        flex: 0 1 120px;
        cursor: pointer;
      }
    }
    .right-function {
      display: flex;
      .right-function-test {
        flex: 0 1 120px;
        margin-right: 20px;
      }
      .right-personal-export {
        flex: 0 1 100px;
        cursor: pointer;
      }
    }
  }
}
</style>