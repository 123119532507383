<!--
 * @Description: 问卷提交成功
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-07 14:49:17
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-15 11:35:51
-->
<template>
  <Header
    v-if="isPc"
    :isShowLogo="true"
    :isForbidLogoClick="true"
  />
  <!-- 电脑端 -->
  <div class="success" v-if="isPc">
   <div class="content">
    <div class="wrapper">
      <img class='icon' src="@/assets/images/success.png" alt="" />
      <p class="text">提交成功</p>
      <p class="tips">{{concludingRemarks}}</p>
    </div>
    
   </div>
  </div>
  <!-- 手机端 -->
  <div class="mobile-success" v-else>
    <div class="wrapper">
      <img class='icon' src="@/assets/images/success.png" alt="">
      <p class="text">提交成功</p>
      <p class="tips">{{concludingRemarks}}</p>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import { useRoute } from 'vue-router'
import { getTerminalType } from '@/utils/userAgent.js'

export default {
  components: {
    Header,
  },
  /**
   * 程序入口
   */
  setup () {
    const route = useRoute()
    const concludingRemarks = route.query.concludingRemarks
    const isPc = getTerminalType() === 'PC' ? true : false
    return {
      concludingRemarks,
      isPc,
    }
  }
}
</script>

<style lang='less' scoped>
// 电脑端
.success {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
  padding: 20px;
  .content {
    width: 100%;
    height: 100%;
    padding-top: 180px;
    min-width: 1000px;
    min-height: 600px;
    background-color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    .icon {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      text-align: center;
    }
    .text {
      margin-top: 16px;
      color: #00A0E9;
      font-size: 26px;
      text-align: center;
    }
    .tips {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      padding: 0 50px;
    }
  }
}
// 手机端
.mobile-success {
  .wrapper {
    margin-top: 120px;
    .icon {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      text-align: center;
    }
    .text {
      margin-top: 16px;
      color: #00A0E9;
      font-size: 26px;
      text-align: center;
    }
    .tips {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      padding: 0 50px;
    }
  }
}
</style>