/*
 * @Description: 判断终端类型
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-09 11:26:46
 * @LastEditors: PSG
 * @LastEditTime: 2021-05-07 17:20:48
 */
export function getTerminalType() {
  let terminalType = ''
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    terminalType = 'WAP'
  } else {
    terminalType = 'PC'
  }
  return terminalType
}